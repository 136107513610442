/* anker anchor */
function scrollableElement(els) {
    for (var i = 0, argLength = arguments.length; i < argLength; i++) {
        var el = arguments[i],
            $scrollElement = $(el);
        if ($scrollElement.scrollTop() > 0) {
            return el;
        } else {
            $scrollElement.scrollTop(1);
            var isScrollable = $scrollElement.scrollTop() > 0;
            $scrollElement.scrollTop(0);
            if (isScrollable) {
                return el;
            }
        }
    }
    return [];
}


/**
 * Refactor ScrollTo Behaviour
 */
$(document.body).on('click', 'a[href*="#"]', function (e) {
    var specialElements = ['#attributes', '#description', '#scopeofdelivery', '#service'];
    var clickedElement = jQuery(this),
        href = clickedElement.attr('href'),
        hashMatch = href.match(/.*(#.*?)$/);
    if (hashMatch !== null) {
        var hash = hashMatch[1];
        if (hash.length > 0) {
            var $target = $(hash);
            if ($target.length > 0) {
                e.preventDefault();
                /* There is a 20 Pixel difference if you call the page with anchor and if you click the anchor navigation. No idea why... */
                var targetOffset = $target.offset().top + 20;
                /* MET-1568: special handling of tabbed infos on details page */
                if(specialElements.indexOf(hash) !== -1) {
                    targetOffset = $target.offset().top - 165;
                }
                var scrollElem = scrollableElement('html', 'body');
                $(scrollElem).animate({scrollTop: targetOffset}, 400, function () {
                });
            }
        }
    }
});
/* anker anchor */


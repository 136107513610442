$(function () {
    subCatToggler();
    burgerCatsToggler();
    scrollHighlighter();
    ScrollHighlighterSearch.init();
});

/**
 * When CatMenu is opened, walk from active category up,
 * open the parent menus and change the togglers
 */
var openCatTree = function () {
    if($('.contentLP').length) {
        var activeCat = $('.secondLevel.hasSubActive');
        activeCat.find(".menuIcon").toggleClass("opened closed");
        activeCat.find(".level_3").slideDown();
    } else {
        var activeCat = $('.activeSubCat');
        $('.firstLevel').find('.subCatToggler').removeClass("opened").addClass("closed");
        activeCat.parents('ul').slideDown();
        activeCat.parents('li').each(function (i, v) {
            if (!$(v).parent('ul').hasClass('firstLevel')) {
                $(v).find('.subCatToggler:first').toggleClass("opened closed")
            }
        });
        activeCat.siblings('ul:first').slideDown();
    }
};

var subCatToggler = function () {
    $('.subCatToggler').on('click', function (e) {
        $(this).next().slideToggle();
        $(this).toggleClass("closed opened");
    });
    $('span.menuIcon').on('click', function (e) {
        $(this).next().slideToggle();
        $(this).toggleClass("closed opened");
    });
};

var burgerCatsToggler = function () {
    $('.menuToggler').not('.contentLP').on('click', function () {
        if ($('.firstLevel').is(':visible')) {
            $('.firstLevel').slideUp(250, function () {
                $('#sidebar.burgermenu').css('width', 'inherit');
                $('.menuToggler').toggleClass('closed opened');
            });
        } else {
            $('#sidebar.burgermenu').css('width', '24%');
            $('.firstLevel').slideDown(250, function () {
                openCatTree();
                $('.menuToggler').toggleClass('closed opened');
            });
        }
    });

    if($('.contentLP').length) {
        $('span.menuIcon').addClass('closed');
        $('.level_3').hide();

        $('.contentLP').on('click', function () {
            if ($('ul#tree').is(':visible')) {
                $('ul#tree').slideUp(250, function () {
                    $('#sidebar.burgermenu').css('width', 'inherit');
                    $('.contentLP').toggleClass('closed opened');
                });
            } else {
                $('#sidebar.burgermenu').css('width', '24%');
                $('ul#tree').slideDown(250, function () {
                    openCatTree();
                    $('.contentLP').toggleClass('closed opened');
                });
            }
        });
    }
};

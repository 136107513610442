/**
 * Created by mtroeger on 02.03.2017.
 */


function adjustImageNoteTextHeight() {
    var noteTextElements = jQuery('.online-dealer .dealer-note'),
        noteText = noteTextElements.text(),
        trimText = jQuery.trim(noteText),
        noteTextLength = trimText.length,
        hasNoteTextElements = noteTextLength > 0;
    if (!hasNoteTextElements) {
        noteTextElements.css('height', 'auto');
    }
}


/*
*   MET-869 - JS-Funktionen für das ElasticSearch Autocomplete-Fenster
*/


var newElasticListener = function () {
    var isMobile = $('body').hasClass('mobileDevice');
    if (!isMobile) {
        var autocompletebox = $('.es-autocomplete');
        var searchbox = $('.search-box .search-box-input-field');
        var pageheadSearchbox = $('.newSearchInput .search-box-input-field');
        var pageheadAutocompletebox = $('.es-autocomplete-header');
        var searchterm = "";

        searchbox.on('keyup', function (e) {
            searchterm = searchbox.val().trim().toLowerCase();
            if(searchterm.length > 0 && searchbox.is(':focus') && e.keyCode != 27) {
                collectDataFromES(searchterm);
            } else {
                autocompletebox.hide();
            }
        });

        pageheadSearchbox.on('keyup', function (e) {
            searchterm = pageheadSearchbox.val().trim().toLowerCase();
            if(searchterm.length > 0 && pageheadSearchbox.is(':focus') && e.keyCode != 27) {
                collectDataFromES(searchterm, "pagehead");
            } else {
                pageheadAutocompletebox.hide();
            }
        });

        $(document).on('keyup', function(e) {
            if(!searchbox.is(':focus')) {
                autocompletebox.hide();
            }
            if(!pageheadSearchbox.is(':focus')) {
                pageheadAutocompletebox.hide();
            }
        });

        autocompletebox.on('click', function(e) {
           e.stopPropagation();
        });

        $(document).on('click', function(e) {
            if(autocompletebox.is(':visible')) {
                autocompletebox.hide();
            } else if(pageheadAutocompletebox.is(':visible')) {
                pageheadAutocompletebox.hide();
            }
        });
    }
};

var collectDataFromES = function(searchterm, sIdent) {
    $.ajax({
        url: esConfig.url,
        type: 'GET',
        data: {
            'lang'       : esConfig.sActLangId,
            'shp'        : esConfig.sESShopId,
            'cl'         : 'search',
            'searchparam': searchterm,
            'q'          : searchterm,
            'query'      : searchterm,
            'type'       : 'result_v2',
            'timestamp'  : $.now(),
            'shopkey'    : esConfig.sFLShopKey,
            'revision_timestamp' : "July 08, 2014 10:12"
        },
        success: function (resp) {
            sortAndDraw(resp, searchterm, sIdent);
        }
    });
};

var sortAndDraw = function(resp,searchterm, sIdent) {
    if(sIdent === "pagehead") {
        var autoCompleteCanvas = $('.es-autocomplete-header');
        var autoCompleteOtherCanvas = $('.es-autocomplete');
    } else {
        var autoCompleteCanvas = $('.es-autocomplete');
        var autoCompleteOtherCanvas = $('.es-autocomplete-header');
    }
    if(searchterm.length > 0 && resp.length > 0) {
        var sortedResponse = sortResponseData(resp, sIdent);
        autoCompleteCanvas.show();
        autoCompleteOtherCanvas.hide();
        drawResultSets(sortedResponse, sIdent);
    } else {
        autoCompleteCanvas.hide();
    }
};

var sortResponseData = function(resp) {
    var sortedResults = {
        'promotions': [],
        'cats'      : [],
        'products'  : [],
        'contents'  : [],
        'suggestions'  : [],
        'landingpages'  : [],
    };

    for(var key in resp) {
        if (resp.hasOwnProperty(key)) {
            var val = resp[key];
            switch(val.block) {
                case 'promotion':
                    sortedResults.promotions.push(val);
                    break;
                case 'suggest':
                    sortedResults.suggestions.push(val);
                    break;
                case 'landingpage':
                    sortedResults.landingpages.push(val);
                    break;
                case 'cat':
                    sortedResults.cats.push(val);
                    break;
                case 'product':
                    val.identifier.indexOf('content') != -1 ? sortedResults.contents.push(val) : sortedResults.products.push(val);
                    break;
            }
        }
    }
    return sortedResults;
};

var drawResultSets = function(sResponse, sIdent) {
    drawPromotions(sResponse.promotions, sIdent);
    drawCategories(sResponse.cats, sIdent);
    drawSuggestions(sResponse.suggestions, sIdent);
    drawContents(sResponse.contents, sIdent);
    drawProducts(sResponse.products, sIdent);
    drawFooter(sIdent);
    checkForBoxes();
};

var highlightSearchTerm = function(highlightThis, sIdent) {
//            Wird obsolet, wenn wir Elasticsearchs Highlightfunktion verwenden??
    if(sIdent === 'pagehead') {
        var searchterm = $('.newSearchInput .search-box-input-field').val().toLowerCase();
    } else {
        var searchterm = $('.search-box .search-box-input-field').val().toLowerCase();
    }
    var searchable = highlightThis.toLowerCase();
    var firstEncounter = searchable.indexOf(searchterm);
    if(firstEncounter > -1) {

        highlightThis = highlightThis.slice(0,firstEncounter) + '<em>' + highlightThis.slice(firstEncounter, firstEncounter+searchterm.length) + '</em>' + highlightThis.slice(firstEncounter+searchterm.length);

    }
    return highlightThis;
};

var generateLinkedResult = function(type, label, sublabel, url, imageUrl, category, sIdent) {
    var generatedLink;
    // MET-1540:
    url = url.toLowerCase();
    switch (type) {
        case 'category':
            if(url.indexOf('.test.') > 0) {
                url = repairTestProduct(url);
            }
            generatedLink = "<a href=\"" + url+ "\">" + label + "</a>";
            break;
        case 'promotion':
            generatedLink = "<a href=\"" + url + "\"><img src=\"" + imageUrl + "\" /></a>";
            break;
        case 'suggestion':
            generatedLink = "<a href=\"" + esConfig.sCatSearchLink + "&lang="+ esConfig.sActLangId +"&searchparam=" + label + "\">" + highlightSearchTerm(label, sIdent) + "</a>";
            break;
        case 'content':
            // if(label.length > 23) {
            //     label = label.substr(0, 23);
            //     label += '…';
            // }
            generatedLink = "<a href=\"" + esConfig.sShopUrl + url + "\">" + highlightSearchTerm(label, sIdent) + "</a>";
            break;
        case 'products':
            if(url.indexOf('.test.') > 0) {
                url = repairTestProduct(url);
            }
            var linkTextHtml = label;
            if (sublabel.trim() != '') {
                linkTextHtml = linkTextHtml + "<br><span class=\"es-product-sublabel\">" + sublabel + "</span>";
            }
            var imageHtml = '';
            if (imageUrl.trim() != '') {
                imageHtml = "<img src=\"" + imageUrl + "\" />";
            }
            generatedLink = "<a href=\"" + url + "\"><span class=\"es-product-image\">" + imageHtml + "</span>" + linkTextHtml +"</a>";
            break;
    }
    return generatedLink;
};

var repairTestProduct = function(url) {
    var countryAbbr = url.slice(7, 10);
    url = url.replace(countryAbbr,'');
    countryAbbr = countryAbbr.slice(0, -1);
    url = url.replace('.com', '.com/'+countryAbbr);
    return url;
};

var prepareCategory = function(category, sIdent) {
    var splittedCategory = category.label.split('_');
    var preparedCategory = "";
    $.each(splittedCategory, function(index, value) {
        if (index < splittedCategory.length-1) {
            preparedCategory += value + " <span class=\"iconFont icon-icon_pfeil_offen_re\"></span> ";
        } else {
            preparedCategory += highlightSearchTerm(value, sIdent);
        }
    });
    var categoryWithReplacedSpaces = category.label.split(' ').join('+');

    preparedCategory = generateLinkedResult('category', preparedCategory, '', category.url, '', categoryWithReplacedSpaces, sIdent);
    return preparedCategory;
};

var drawCategories = function(data, sIdent) {
    if(data.length > 0) {
        var i=0;
        var categoriesMarkup = "";
        var preparedCategory = "";

        while(i < data.length) {
            preparedCategory = prepareCategory(data[i], sIdent);
            categoriesMarkup += "<li>" + preparedCategory + "</li>";
            i++;
        }
        $('.es-resultsbox-categories ul').html(categoriesMarkup);
        $('.es-resultsbox-categories').show();
    } else {
        $('.es-resultsbox-categories').hide();
    }
};

var drawPromotions = function(data, sIdent) {
    if(data.length > 0) {
        var i=0;
        var promotionsMarkup = "";
        var preparedPromotion;
        var rawContent;

        while(i < data.length) {
            rawContent = data[i];
            preparedPromotion = generateLinkedResult('promotion', '', '', rawContent.url, rawContent.imageUrl, '', sIdent);
            promotionsMarkup += "<li>" + preparedPromotion + "</li>";
            i++;
        }
        $('.es-resultsbox-promo ul').html(promotionsMarkup);
        $('.es-resultsbox-promo').show();
    } else {
        $('.es-resultsbox-promo').hide();
    }
};

var drawSuggestions = function(data, sIdent) {
    if(data.length > 0) {
        var i=0;
        var suggestionsMarkup = "";
        var preparedSuggestion;
        while(i < data.length) {
            preparedSuggestion = generateLinkedResult('suggestion', data[i].label, '', '', '', '', sIdent);
            suggestionsMarkup += "<li>" + preparedSuggestion + "</li>";
            i++;
        }
        $('.es-resultsbox-suggestions ul').html(suggestionsMarkup);
        $('.es-resultsbox-suggestions').show();
    } else {
        $('.es-resultsbox-suggestions').hide();
    }
};

var drawContents = function(data, sIdent) {
    if(data.length > 0) {
        var i=0;
        var contentMarkup = "";
        var preparedContent;
        var rawContent;

        while(i < data.length) {
            rawContent = data[i];
            preparedContent = generateLinkedResult('content', rawContent.label, '', rawContent.url, rawContent.imageUrl, '', sIdent);
            contentMarkup += "<li>" + preparedContent + "</li>";
            i++;
        }
        $('.es-resultsbox-content ul').html(contentMarkup);
        $('.es-resultsbox-content').show();
    } else {
        $('.es-resultsbox-content').hide();
    }
};

var drawProducts = function(data, sIdent) {
    if(data.length > 0) {
        var i=0;
        var productMarkup = "";
        while(i < data.length) {
            var rawProducts = data[i];
            var preparedProducts = generateLinkedResult(
                'products',
                highlightSearchTerm(rawProducts.label, sIdent),
                highlightSearchTerm(rawProducts.sublabel, sIdent),
                rawProducts.url,
                rawProducts.imageUrl,
                ''
            );
            productMarkup += "<li>" + preparedProducts + "</li>";
            i++;
        }
        $('.es-resultsbox-products ul').html(productMarkup);
        $('.es-resultsbox-products').show();
    } else {
        $('.es-resultsbox-products').hide();
    }
};

var drawFooter = function(sIdent) {
    if(sIdent === 'pagehead') {
        var searchterm = $('.newSearchInput .search-box-input-field').val();
    } else {
        var searchterm = $('.search-box .search-box-input-field').val();
    }
    var footerMarkup;
    if(searchterm) {
        footerMarkup = "<a href=\""+ esConfig.sCatSearchLink +"&lang="+ esConfig.sActLangId +"&shp="+ esConfig.sESShopId +"&searchparam=" + searchterm + "\">" + esLang.AUTOSUGGEST_ALLRESULTS + " <em>" + searchterm + "</em></a>";
        $('.es-resultsbox-footer span').html(footerMarkup);
        $('.es-resultsbox-footer').show();
    } else {
        $('.es-resultsbox-footer').hide();
    }
};

var checkForBoxes = function() {

    var suggestions = $('.es-resultsbox-suggestions').css('display')=='block';
    var products = $('.es-resultsbox-products').css('display')=='block';
    var categories = $('.es-resultsbox-categories').css('display')=='block';
    var content = $('.es-resultsbox-content').css('display')=='block';
    var leftBox = (suggestions || products);
    var rightBox = (categories || content);
    if((!leftBox) && rightBox) {
        $('.es-resultsbox-column-left').hide();
        $('.es-resultsbox-column-right').removeClass('es-half').show();
    } else if(leftBox && (!rightBox)) {
        $('.es-resultsbox-column-left').removeClass('es-half').show();
        $('.es-resultsbox-column-right').hide();
    } else {
        $('.es-resultsbox-column-left').addClass('es-half').show();
        $('.es-resultsbox-column-right').addClass('es-half').show();
    }
};

if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', newElasticListener, false);
} else if (document.attachEvent) {
    document.attachEvent('DOMContentLoaded', newElasticListener);
}
var ceContentSlider = {

    owl: null,

    init: function () {
        ceContentSlider.owl = $(".ce-contentSlider .owl-carousel");
        ceContentSlider.owl.owlCarousel({
            center: true,
            items: 1,
            loop: true,
            mouseDrag: true,
            touchDrag: true,
            fluidSpeed: 400,
            navSpeed: 400,
            dotsSpeed: 400,
            nav: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            autoplaySpeed: 600,
            navText: ["", ""],
            dots: true
        });

        if ($(ceContentSlider.owl).attr('data-num-slides') > 1) {
            ceContentSlider.owl.bind('mouseenter', function () {
                $(this).find('.owl-nav').fadeIn();
            });
            ceContentSlider.owl.bind('mouseleave', function () {
                $(this).find('.owl-nav').fadeOut();
            });
        }
    }
};

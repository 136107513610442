var dceYouTube = {

    ratio: {
        x: 16,
        y: 9
    },

    items: [],

    init: function () {
        dceYouTube.items = $('.dceYouTube');
        dceYouTube.setIFrameHeight();
        dceYouTube.setWindowResizeEvent();
    },

    setIFrameHeight: function () {
        $(dceYouTube.items).each(function () {
            var $item = $(this);
            var width = $item.find('iframe')[0].clientWidth;
            var height = Math.floor(width / dceYouTube.ratio.x * dceYouTube.ratio.y);
            $item.height(height);
        });
    },

    setWindowResizeEvent: function() {
        window.addEventListener("resize", dceYouTube.setIFrameHeight);
    }
};

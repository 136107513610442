function tn_inlinePop(e,c) {
    var rcId = e;
    var rcCo = c;
    var pop = $('.rconPop');
    var modal = $('.rconPopModal');
    var loader = $('.rConLoader');

    loader.fadeIn('fast');
    modal.fadeIn('fast');

    pop.show(function(r) {
        $.ajax({
            url     :   '/'+rcCo+'/index.php?cl=tn_retailconnect&artid='+rcId,
            method  :   'GET',
            success :   function(w) {
                loader.fadeOut();
                $('.rConPopContent').html(w);
                $('.rConPopCloser').bind('click', function() {
                    pop.hide();
                    modal.hide();
                });
            }
        });
    });

    modal.bind('click', function(ev) {
        if(!$(ev.target).closest(pop).length) {
            if(pop.is(':visible')) {
                pop.hide();
                modal.hide();
            }
        }
    });
}

function getPageCountry() {
    var lAttr = $('html').attr('lang');
    if (lAttr && lAttr.indexOf('-') > -1) {
        var lAttrA = lAttr.split('-');
        var countryCode = lAttrA[1] ? lAttrA[1].toLowerCase() : 'de';
        if(countryCode === 'gb') {
            countryCode = 'uk';
        }
        return countryCode;
    }
}
function setRConListeners() {
    var country = getPageCountry();
    $('a.retailConnect').bind('click', function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        var artid = $(this).attr('data-artid');
        tn_inlinePop(artid, country);
    });
}

$(function() {
    setRConListeners();

    if(window.location.href.indexOf('?shoponline') !== -1) {
        if ($('a.retailConnect').length === 1) {
            var artid = $('a.retailConnect').attr('data-artid');
            var country = getPageCountry();
            tn_inlinePop(artid, country);
        }
    }
});
/**
 *  handle language popup layer
 */
( function( $ ) {

	var nfcLangPopup = {

		options: {
			layerToggleDuration : 'fast',
			layerId : 'mask',
			layerContainerId : 'worldWideContainer',
			containerId : 'page'
		},

		_create: function(){
			var self = this,
				options = self.options,
				el = self.element;

			// put hidden layer to defined container id
			$( el ).parent().find( '#' + options.layerContainerId ).appendTo( $( '#' + options.containerId ) );

			// handle layer show
			$( 'a', el ).click( function( ev ) {
				ev.preventDefault();
				self.showPopupLayer();
			} );

			// handle layer close
			$( '#' + options.layerContainerId + ' .close' ).click( function( ev ) {
				ev.preventDefault();
				self.hidePopupLayer();
			} );
			$( document ).on( 'click', '#' + options.layerId, function( ev ) {
				console.log('test');
				self.hidePopupLayer();
			} );
		},

		showPopupLayer : function() {
			var self = this,
				options	= self.options,
				layerId = options.layerId,
				containerId = options.containerId,
				layerContainerId = options.layerContainerId

			var layer = '<div id="' + layerId + '"></div>';
			if( $( '#' + containerId ).find( '#' + layerId ).size() == 0 ) {
				$( layer ).appendTo( $( '#' + containerId ) );
			}

			if ($( '#' + layerId ).is(":visible")) {
				$('.country-title-wrapper.open').removeClass('open');
			}
			else {
				$('.country-title-wrapper').addClass('open');
			}

			$( '#' + layerId ).fadeToggle( options.layerToggleDuration );
			$( '#' + layerContainerId ).fadeToggle(  options.layerToggleDuration );
		},

		hidePopupLayer : function() {
			var self = this,
				options = self.options
				layerId = options.layerId,
				layerContainerId = options.layerContainerId

			$( '#' + layerId ).fadeOut( options.layerToggleDuration, function(){
				$(this).hide();
			} );
			$( '#' + layerContainerId ).hide();
			$('.country-title-wrapper.open').removeClass('open');
		}
	}

	$.widget( "ui.nfcLangPopup", nfcLangPopup );

} )( jQuery );
/**
 *  handle video popup on detail page
 */
( function( $ ) {

	var nfcAttributeVideo = {
		options: {
			wrapperVideo : '.productVideoWrapperStartIcon',
			containerVideoFrame : '.productVideoWrapper',
			playButton : '.productVideo',

			thumbnailContainerMainPage : '#morePicsContainer',
			thumbnailIconMainPage : '.thumbnail',

			thumbnailContainerZoomPopUp : '#popupGallery .pictureGallery',
			thumbnailIconZoomPopUp : '.thumbnail',

			videoContainerMainImage : '.picture #wrap',
			videoContainerZoomImage : '#zoomed'
		},

		currentVideoPlayback : {
			'id' : false,
			'src': false
		},


		_create: function(){
			var self = this,
				options = self.options;

			// handle display for video icon on thumbnails and main image
			self.handleVideoIconsOnDetailPage();

			// handle display for video icon for thumbnails and zoom image on zoom popup
			self.showVideoIconsOnZoomPopUp();

			self.handleVideoOnMainImage();

			self.handleVideoOnZoomPopup();

			self.handleZoomTrigger();

			// stop video on popup close
			$( '#zoomModal .closePop').on( 'click', function() {
				self.stopVideo();
			} );
		},

		handleVideoIconsOnDetailPage : function() {
			var self = this,
				options = self.options,
				thumbnailContainerMainPage = options.thumbnailContainerMainPage,
				thumbnailIconMainPage = options.thumbnailIconMainPage;
			if( $( thumbnailIconMainPage, thumbnailContainerMainPage ).size() > 0 ) {
				$( thumbnailIconMainPage, thumbnailContainerMainPage ).each( function( idxThumbnail ) {
					var currentThumbnail = $( this );

					// handle click on thumbnail player item
					$( this ).find('.productVideoSmall').on( 'click', function() {
						// trigger click on thumbnail
						$( this ).parents( '.thumbnail' ).find( 'a' ).trigger( 'click' );
					} );

					// handle click on thumbnail
					$( this ).find( 'a' ).on( 'click', function() {
						var thumbIconVideoNo = $( this ).data( 'video-nr' );
						var thumbIconClass = $( this ).data( 'class' );
						self.showVideoIconsOnDetailPage( thumbIconVideoNo, thumbIconClass );
					} );

					// trigger click on first thumbnail
					// in case of video to handle zoom correctly
					if( idxThumbnail == 0 ) {
						var firstThumbnail = $( this ).first().find( 'a' );
						if( firstThumbnail.size() > 0 && firstThumbnail.data( 'video-nr' ) != undefined ) {
							firstThumbnail.trigger( 'click' );
						}
					}
				} );
			}
		},

		showVideoIconsOnDetailPage : function( thumbIconVideoNo, thumbIconClass ) {
			var self = this,
				options = self.options,
				wrapperVideo = options.wrapperVideo,
				videoContainerMainImage = options.videoContainerMainImage,
				videoIcon =  $( videoContainerMainImage ).find( wrapperVideo );

			if( thumbIconVideoNo != undefined && videoIcon.size() > 0 ) {
				$( 'a img', videoContainerMainImage ).data( 'video-nr', thumbIconVideoNo );
				videoIcon.find('.productVideo').removeClass().addClass(thumbIconClass+' productVideo');
				videoIcon.find('iframe').not(thumbIconClass).hide();
				videoIcon.find('iframe.'+thumbIconClass).show();
				videoIcon.show();
			} else {
				$( 'a img', videoContainerMainImage ).removeData( 'video-nr' );
				videoIcon.find('.productVideo').removeClass().addClass('productVideo');
				videoIcon.find('iframe').hide();
				videoIcon.hide();
			}
		},

		handleVideoOnMainImage : function() {
			var self = this,
				options = self.options,
				videoContainerMainImage = options.videoContainerMainImage;

			// trigger with zooming glass
			var oZoomTrigger = $(videoContainerMainImage).siblings('#zoomTrigger');
			oZoomTrigger.unbind('click');

			oZoomTrigger.on( 'click', function() {
				$( 'a', videoContainerMainImage).trigger('click');
			});

			// handle click on main image in case of video
			$( 'a', videoContainerMainImage ).on( 'click', function() {
				var mainImg = $( this ).find( 'img' );
				if( mainImg.size() > 0 ) {
					var videoId = mainImg.data( 'video-nr' );
					if (typeof videoId !== 'undefined') {
						$('#zoomed .productVideoWrapperStartIcon').hide();
						$('#zoomed .productVideoWrapperStartIcon[data-video-nr='+videoId+']').show().trigger('click');
					}

				}
			} );
		},

		showVideoIconsOnZoomPopUp : function() {
			var self = this,
				options = self.options,
				thumbnailContainerZoomPopUp = options.thumbnailContainerZoomPopUp,
				thumbnailIconZoomPopUp = options.thumbnailIconZoomPopUp,
				wrapperVideo = options.wrapperVideo,
				playButton = options.playButton,
				videoContainerZoomImage = options.videoContainerZoomImage

			var zoomThumbnails = $( thumbnailIconZoomPopUp, thumbnailContainerZoomPopUp );
			if( zoomThumbnails.size() > 0 ) {
				zoomThumbnails.each( function() {
					var that = $( this );

					// trigger video switch on play button thumbnail
					that.find( '.productVideoSmall' ).on( 'click', function() {
						that.find( 'img' ).trigger( 'click' );
					} );

					// video switch on thumbnail switch
					that.find( 'img' ).on( 'click', function() {
						// stop video playback on image switch
						self.stopVideo();
						var videoId = that.data( 'video-nr' );
						if (typeof videoId !== 'undefined') {
							$('#zoomed .productVideoWrapperStartIcon').hide();
							$('#zoomed .productVideoWrapperStartIcon[data-video-nr='+videoId+']').show().trigger('click');
						} else {
							$( '#zoomImg', videoContainerZoomImage ).show();
						}

					} );
				} );
			}
		},

		handleVideoOnZoomPopup : function() {
			var self = this,
				options = self.options,
				wrapperVideo = options.wrapperVideo,
				containerVideoFrame = options.containerVideoFrame,
				playButton = options.playButton,
				videoContainerZoomImage = options.videoContainerZoomImage;

			// handle video play button on zoom popup
			$( wrapperVideo, videoContainerZoomImage ).on( 'click', function() {
				// hide zoomed image
				$( '#zoomImg', videoContainerZoomImage ).hide();

				// hide play button on zoomed image
				$( wrapperVideo, videoContainerZoomImage ).find( playButton ).hide();

				var videoWrapper = $( this ).find( containerVideoFrame );
				if( videoWrapper.size() > 0 ) {
					var videoId = $( 'iframe', videoWrapper ).attr( 'id' );
					var videoSrc = $( 'iframe', videoWrapper ).attr( 'src' );

					if( videoId != undefined && videoSrc != undefined ) {
						self.currentVideoPlayback.id = videoId;
						self.currentVideoPlayback.src = videoSrc;
						self.playVideo();
					}
				}
			} );
		},

		playVideo : function() {
			var self = this,
				videoContainerZoomImage = self.options.videoContainerZoomImage

			if( self.currentVideoPlayback.id != undefined && self.currentVideoPlayback.src != undefined ) {
				var video = $( videoContainerZoomImage).find( 'iframe[id=' + self.currentVideoPlayback.id + ']' );
				if( video.size() > 0) {
					video.parent().show();
					if(video.data('autoplay') == '1'){
						video.attr( 'src', self.currentVideoPlayback.src + '&autoplay=1' );
					}
				}
			}
		},

		stopVideo : function() {
			var self = this,
				videoContainerZoomImage = self.options.videoContainerZoomImage

			if( self.currentVideoPlayback.id != undefined && self.currentVideoPlayback.src != undefined ) {
				var video = $( videoContainerZoomImage).find( 'iframe[id=' + self.currentVideoPlayback.id + ']' );
				if( video.size() > 0 ) {
					video.parent().hide();
					video.attr( 'src', self.currentVideoPlayback.src );
				}
			}
		},

		handleZoomTrigger: function() {
			$('#zoomTrigger').click(function(e){
				var videoVisible = false;
				$('#zoomed .productVideoWrapperStartIcon').each(function(){
					if ($(this).is(':visible')) {
						videoVisible = true;
					}
				});
				if (!videoVisible) {
					$('#zoomed img').show();
				}
			});
		}

	}

	$.widget( "ui.nfcAttributeVideo", nfcAttributeVideo );

} )( jQuery );
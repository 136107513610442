/**
 * Created by mtroeger on 04.08.2016.
 */


var neustaSlider = {

    init: function () {
        var owl = $('.tx-neustaslider .owl-carousel');

        if (owl.length > 0) {

            var firstSlideImgArray = $('.tx-neustaslider .owl-carousel .item:first div > img');
            if (firstSlideImgArray.length > 0) {

                var firstSlideImg = firstSlideImgArray[0];
                var originWidth = firstSlideImg.attributes.width.value;
                var originFontSize = parseFloat(owl.css('font-size'));
                var numSlides = $(owl).attr('data-num-slides');

                if(numSlides > 1) {
                    owl.owlCarousel({
                        loop: true,
                        items: 1,
                        nav: true,
                        autoplay: true,
                        autoplayTimeout: 5000,
                        // touchDrag: false,
                        mouseDrag: false,
                        dotsEach: true,
                        autoplayHoverPause: true,
                        autoplaySpeed: 600,
                        navText: ["", ""],
                        navSpeed: 400,
                        dotsSpeed: 400
                    });
                } else {
                    owl.owlCarousel({
                        loop: false,
                        items: 1,
                        nav: false,
                        autoplay: false,
                        autoplayTimeout: 0,
                        touchDrag: false,
                        mouseDrag: false,
                        dotsEach: false,
                        autoplayHoverPause: true,
                        autoplaySpeed: 0,
                        navText: ["", ""],
                        navSpeed: 400,
                        dotsSpeed: 400
                    });
                }

                neustaSlider.owlSliderResizeDoings(owl, originWidth, originFontSize);

                owl.on('resize.owl.carousel', function () {
                    neustaSlider.owlSliderResizeDoings(this, originWidth, originFontSize);
                });

                if (numSlides > 1) {
                    owl.bind('mouseenter', function () {
                        $(this).find('.owl-nav').fadeIn();
                    });
                    owl.bind('mouseleave', function () {
                        $(this).find('.owl-nav').fadeOut();
                    });
                }

            }
        }
    },

    owlSliderResizeDoings: function (slider, originWidth, originFontSize) {

        var currentSliderWidth = $(slider).width();
        var factor = originWidth / currentSliderWidth;

        //Set OnTopImage size
        $(slider).find('.slideTopImage > img').each(function () {
            var newWidth = (this.attributes.width.value / factor);
            var newHeight = (this.attributes.height.value / factor);
            $(this).width(newWidth + 'px').height(newHeight + 'px');
        });

        //Set font size
        $(slider).css('font-size', originFontSize / factor + 'px');
    },

};

var HotspotTextSlider = {

    owl: null,

    init: function () {
        HotspotTextSlider.owl = $(".hotspotTextSlider");
        HotspotTextSlider.owl.each(function () {
            var $thisSlider = $(this);
            HotspotTextSlider.setHotspotStageId($thisSlider);

            $thisSlider.owlCarousel(
                {
                    center: true,
                    items: 1,
                    loop: true,
                    mouseDrag: true,
                    touchDrag: true,
                    fluidSpeed: 500,
                    navSpeed: 500,
                    nav: true,
                    navText: ["", ""],
                    dots: false,
                    margin: 30,
                }
            );

            $thisSlider.on('changed.owl.carousel', function (event) {
                HotspotTextSlider.removeClassFromAllSlides($thisSlider);
                HotspotTextSlider.addClassToCurrentSlide(event, $thisSlider);
            });
            HotspotTextSlider.setClickEvents($thisSlider);
        });
    },

    removeClassFromAllSlides: function ($thisSlider) {
        var hotspotStageId = HotspotTextSlider.getHotspotStageId($thisSlider);
        $('#' + hotspotStageId + ' .spot').removeClass('active');
    },

    addClassToCurrentSlide: function (event, $thisSlider) {
        var indexOfActiveItem = event.item.index;
        var activeItem = $thisSlider.find('.item')[indexOfActiveItem];
        var hotspotIdToActiveItem = activeItem.dataset.hotspotid;
        $('#' + hotspotIdToActiveItem + ' .spot').first().addClass('active');
    },

    setClickEvents: function($thisSlider) {
        var hotspotStageId = HotspotTextSlider.getHotspotStageId($thisSlider);
        $('#' + hotspotStageId + ' .spot').click(function(){
            var index = $(this).parent()[0].dataset.index;
            $thisSlider.trigger('to.owl.carousel', [index,300]);

            // scroll to textslider, if on mobile
            if ($('body').hasClass('mobileDevice')) {
                var textArea = $('#' + hotspotStageId + ' .hotspotTextSlider');
                $('html, body').animate({
                    scrollTop: textArea.offset().top - 150
                }, 500);
            }
        });
    },

    setHotspotStageId: function ($thisSlider) {
        var extractedId =  $thisSlider[0].getAttribute('id').match(/-([0-9]+)$/)[1];
        var hotspotStageId = 'hotspotstage-' + extractedId;
        jQuery.data($thisSlider, 'hotspotStageId', hotspotStageId);
    },

    getHotspotStageId: function ($thisSlider) {
        return jQuery.data($thisSlider, 'hotspotStageId');
    }

};
HotspotTextSlider.init();
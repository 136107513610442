var bottomFlyout = {
    setup: {
        flyInAfterPixel: 150,
        opened: false,
        cookieID: 'flyout'+document.location.pathname
    },
    init: function () {
        bottomFlyout.appendCloseButton();
        bottomFlyout.bindEvents();
    },
    flyIn: function () {
        if (bottomFlyout.setup.opened !== true) {
            bottomFlyout.setup.opened = true;
            var panel = $('#pageBottom');
            var panelHeight = panel.innerHeight() + 'px';
            panel.css('bottom', '-' + panelHeight)
                .css('display', 'block')
                .css('opacity', 1)
                .animate({
                bottom: 0
            }, 900);
        }
    },
    flyOut: function () {
        if (bottomFlyout.setup.opened === true) {
            bottomFlyout.setup.opened = false;
            var panel = $('#pageBottom');
            var panelHeight = panel.innerHeight() + 'px';
            panel.animate({
                bottom: '-' + panelHeight,
                opacity: 0
            }, 300, function () {
                panel.hide();
            });
        }
    },
    checkAndFly: function (win) {
        if (document.cookie.indexOf(bottomFlyout.setup.cookieID + "=true") === -1) {
            if ($('#footer').hasClass('fix')) {
                if ($(win).scrollTop() > bottomFlyout.setup.flyInAfterPixel) {
                    bottomFlyout.flyIn();
                } else if (bottomFlyout.setup.opened === true) {
                    bottomFlyout.flyOut();
                }
            } else {
                bottomFlyout.flyOut();
            }
        }
    },
    appendCloseButton: function () {
        var closeLink = '<a href="" class="flyoutCloseButton"></a>';
        $('#pageBottom').append(closeLink);
    },
    closeAndCookie: function () {
        document.cookie = bottomFlyout.setup.cookieID + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        bottomFlyout.flyOut();
        return false;
    },
    bindEvents: function () {
        $(window).on("scroll", function () {
            bottomFlyout.checkAndFly(this);
        });

        $('#pageBottom').find('a.flyoutCloseButton').on('click', bottomFlyout.closeAndCookie);
    }
};
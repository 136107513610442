var Hotspots = {
    domspots : '',
    hotspotStage: '',
    hotspotImage: '',
    baseFontSize: '',

    textOffsetInRem: 3,
    isMobile:false,

    init: function () {
        this.baseFontSize = parseFloat($('html').css('font-size'));
        this.domspots = $('.hotspot');
        this.hotspotStage = $('.ce-hotspotstage');
        this.hotspotImage = this.hotspotStage.find('.hotspotImage');
        this.isMobile = $("body").hasClass('mobileDevice');
        $.each(this.domspots, function (key,hs) {
            Hotspots.adjustPlacement(hs);
        });
        Hotspots.bind(this.domspots);
    },

    bind: function (hotspots) {
        $(document).on("scroll", function () {
            $.each(hotspots, function (key,hs) {
                if( Hotspots.isScrolledIntoView(hs)  ) {
                    if(!this.isMobile) Hotspots.drawLine(hs, hs.lineLength);
                }
            });
        });


    },

    isScrolledIntoView: function (hotspot) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(hotspot).offset().top;
        var elemBottom = elemTop + $(hotspot).height() + 150;

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    },

    convertToRem: function (pxVal) {
        // return two digit float
        return Math.round( (pxVal / this.baseFontSize) * 100 ) / 100;
    },
    convertToPx: function (remVal) {
        // return int
        return Math.round( remVal * this.baseFontSize );
    },

    adjustPlacement: function (hotspot) {
        var hotspotData = $(hotspot).data();
        var hsTextContainers = $(hotspot).find('.hotspotTexts');
        var headlineContainer = $(hsTextContainers[1]);
        var newX, lineLength;
        // metabo wants to input the Y-value as '% from bottom', so...
        hotspotData.posY = 100 - hotspotData.posY;

        $(hotspot).css('top', hotspotData.posY + '%').css('left', hotspotData.posX + '%');

        if(!this.isMobile) {
            if(hotspotData.direction === 1) {
                newX = this.hotspotStage.width() + this.hotspotStage.offset().left - (headlineContainer.offset().left + headlineContainer.width()) - Hotspots.convertToPx(Hotspots.textOffsetInRem);
                lineLength = newX + hsTextContainers.width() - Hotspots.convertToPx(2);
            } else {
                newX = (this.hotspotStage.offset().left - headlineContainer.offset().left) + Hotspots.convertToPx(Hotspots.textOffsetInRem);
                lineLength = $(hotspot).offset().left - this.hotspotStage.offset().left - $(hotspot).find('.spot').width() - Hotspots.convertToPx(Hotspots.textOffsetInRem);
            }
            hotspot.lineLength = lineLength;
        } else {
            newX = ($(hotspot).offset().left - ((this.hotspotStage.width() - headlineContainer.width()) / 2) ) * -1;
        }
        hsTextContainers.css('left', Hotspots.convertToRem(newX) + 'rem');
    },

    drawLine: function (hotspot, lineLength) {
        var line = $(hotspot).find('.spotLine');
        line.css('width', Hotspots.convertToRem(lineLength) + 'rem');
    }
};

$(function(){
    Hotspots.init();
    HotspotTextSlider.init();
});

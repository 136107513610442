
var scrollHighlighter = function () {

    var lastId,
        menuItems = $(".greyJumper").find("a"),
        topMenu = $(".greyWorm"),
        header = $('#header');


    function sortTargets(targetTags) {

        //copy values into array
        var arr = [];
        targetTags.map(function(){
            arr.push(this[0]);
        });

        //sort the array
        arr.sort(function(a,b){
            if (a.offsetTop > b.offsetTop) {
                return 1;
            }
            if (a.offsetTop < b.offsetTop) {
                return -1;
            }
            return 0;
        });

        //build new mapping based on the sorted array
        var i = -1;
        var sorted = targetTags.map(function(){
            i++;
            return $(arr[i]);
        });

        return sorted;
    }


    if (menuItems.length) {
        var topMenuHeight = topMenu.outerHeight() + header.outerHeight(),
            targetTags = menuItems.map(function () {
                var item = $($(this).attr("href"));
                if (item.length) {
                    return item;
                }
            });
        targetTags = sortTargets(targetTags);

        $(window).on('scroll', function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight + 20;

            // Get id of current scroll item
            var cur = targetTags.map(function () {
                if ($(this).offset().top < fromTop)
                    return this;
            });

            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;

                menuItems
                    .parent().removeClass("highlighted")
                    .end().filter("[href=#" + id + "]").parent().each(function () {
                    $(this).addClass("highlighted");
                });

                if ($('body').hasClass('mobileDevice')) {

                    var parentListElement = menuItems.filter("[href=#" + id + "]").parent();
                    if (parentListElement.length) {

                        var windowWidth = $(window).outerWidth();
                        var leftPosition = parentListElement.offset().left;
                        var scrollVal = $('.greyJumper').scrollLeft();

                        if ((leftPosition + parentListElement.outerWidth()) > windowWidth) {
                            scrollVal += parentListElement.outerWidth();
                        } else if (leftPosition < 0) {
                            scrollVal += leftPosition - 26;
                        }

                        $('.greyJumper').animate({scrollLeft: scrollVal}, 400);
                    }
                }
            }
        });
    }
};


var ScrollHighlighterSearch = {

    aMenuItems :    '',
    bHasSubmenu :   '',
    aSubmenuItems : '',

    init :  function () {
        this.aMenuItems = $('.searchUpperBand ul li');
        this.bHasSubmenu = $('.searchLowerBand .subLine').hasClass( 'active' );
        if( this.bHasSubmenu ) {
            this.aSubmenuItems = $('.subLine.active ul li');
        }
        this.scrollInView();

        // Den Link für "weitere [Unterkategorien] anzeigen" nur einblenden, wenn auch Unterkategorien verdeckt (overflow) sind
        if(($('.subLine.active .sUB-secondLevel').outerHeight() + $('.subLine.active .sUB-secondLevel li').outerHeight()) > $('.subLine.active .sUB-secondLevel').prop('scrollHeight')) {
            $('.showMoreSubcats').hide();
        }

        var activeLinkTopPos  = $('.sUB-secondLevel.cutActive a.active');
        if(activeLinkTopPos.length) {
            if( activeLinkTopPos.position().top > $('.sUB-secondLevel.cutActive a.active').parent().parent().outerHeight() )
            {
                $('.showMoreSubcats').removeClass('active');
                $('.showLessSubcats').addClass('active');
                $('.sUB-secondLevel').removeClass('cutActive');
            }
        }
    },

    scrollInView :  function () {

        var windowWidth = $(window).outerWidth();
        var activeMenuItem = this.aMenuItems.find('a.active');
        if (activeMenuItem.length) {
            var rightEdge = activeMenuItem.offset().left + activeMenuItem.parent().outerWidth() + 25;
            var scrollVal = windowWidth - rightEdge;
            $('.searchUpperBand').animate({scrollLeft: -scrollVal}, 400);
        }

    }
};


'use strict';
var scrollToTop = {
    setup: {
        scrollToThisTopPosition: 0,
        showButtonAfterPixel: 100,
        animationDuration: 800,
        elementHeightInPixels: 52,
        elementMarginInPixels: 10
    },
    init: function () {
        scrollToTop.buildButton();
        scrollToTop.bindEvents();
    },
    bindEvents: function () {
        $(window).on("scroll", function () {
            $('.back-to-top').offset({
                top: $(this).scrollTop() + $(window).height() - (scrollToTop.setup.elementHeightInPixels + scrollToTop.setup.elementMarginInPixels)
            });
            if ($(this).scrollTop() > scrollToTop.setup.showButtonAfterPixel) {

                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });
        $('.back-to-top').on("click", function () {
            $('body,html').animate({
                scrollTop: scrollToTop.setup.scrollToThisTopPosition
            }, scrollToTop.setup.animationDuration);
            return false;
        });
    },
    buildButton: function () {
        var back_to_top_button = ['<a href="#top" class="back-to-top">&nbsp;</a>'].join("");
        $("#page").append(back_to_top_button);
        $(".back-to-top").hide();
    }
};
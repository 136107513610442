
$(function(){
    var table = $(".sortable").stupidtable();
    if (table.length > 0) {
        table.on("aftertablesort", function (event, data) {
            var th = $(this).find("th");
            th.find(".triangle-wrapper").remove();
            var dir = $.fn.stupidtable.dir;
            th.eq(data.column).append('<span class="triangle-wrapper ' + data.direction + '"><span></span></span></span>');
        });
        var th_to_sort = table.find("th").first();
        if (th_to_sort.length > 0) {
            th_to_sort.stupidsort('asc');
        }
    }
});

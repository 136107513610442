
var neustaCarousel = {

    owl: null,

    init: function () {
        neustaCarousel.owl = $(".filmSlider .owl-carousel");
        neustaCarousel.owl.each(function () {
            var $thisCarousel = $(this);
            $thisCarousel.owlCarousel(
                {
                    center: true,
                    items: 2,
                    loop: true,
                    mouseDrag: true,
                    touchDrag: true,
                    fluidSpeed: 500,
                    navSpeed: 500,
                    nav: false,
                    navText: ["", ""],
                    dots: false,
                    margin: 30,
                    responsive: {
                        600: {
                            items: 3,
                            nav: true,
                            margin: 60,
                        }
                    },
                    onTranslated: function () {
                        neustaCarousel.onTranslatedCallback($thisCarousel);
                    },
                }
            );
            neustaCarousel.setClickEvents($thisCarousel, true);
        });
    },

    onTranslatedCallback: function ($thisCarousel) {
        neustaCarousel.setClickEvents($thisCarousel);
    },

    setClickEvents: function ($thisCarousel, initialCall) {
        var $currentItem = $thisCarousel.find('.owl-item.active.center');
        var nextItem = $currentItem.next();
        var prevItem = $currentItem.prev();

        //Remove old click events
        $thisCarousel.find('.owl-item').unbind();

        //Set filmsModal if there're videos
        var $videoOpen = $thisCarousel.find('.videoOpen');
        if (!initialCall && $videoOpen.length > 0) {
            $videoOpen.unbind();
            // Set film modal event
            filmsModal.setClickEvents($currentItem.get(0).firstChild);
        }

        //Set slider navigation events
        prevItem.click(function (event) {
            $thisCarousel.trigger('prev.owl.carousel', [300]);
            event.preventDefault();
        });
        nextItem.click(function (event) {
            $thisCarousel.trigger('next.owl.carousel', [300]);
            event.preventDefault();
        });
    }
};

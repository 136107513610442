var lazyLoading = function () {
    $('img.lazyLoad').each(function () {
        if (this.srcset === '' && this.dataset.srcset !== undefined) {
            this.srcset = this.dataset.srcset
        }
        if (this.src === '' && this.dataset.src !== undefined) {
            this.src = this.dataset.src
        }
        $(this).removeClass('postLoad');
    })
};

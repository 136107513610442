var sameHeight = {

    isMobile: false,
    blocks: [],

    init: function () {
        this.getPageAttributes();
        if (!this.isMobile) {
            this.findBlocksAndItems();
            this.equalizeHeights();
            this.setEventHandler();
        }
    },

    getPageAttributes: function () {
        this.isMobile = $('body.mobileDevice').length == 1;
    },

    findBlocksAndItems: function () {
        var t = this;
        $('.sameHeight').each(function () {
            var items = [];
            $(this).find('.sameHeight__item').each(function () {
                items.push($(this));
            });
            t.blocks.push(items);
        });
    },

    equalizeHeights: function () {
        if (this.blocks.length > 0) {
            this.blocks.forEach(function (block) {
                //Get max height
                var maxHeight = 0;
                block.forEach(function (item) {
                    if (maxHeight < item.height()) {
                        maxHeight = item.height();
                    }
                });
                //set height to max height
                block.forEach(function (item) {
                    item.height(maxHeight);
                });
            });
        }
    },

    setEventHandler: function () {
        var t=this;
        $(window).resize(function(){
            t.removeEqualHeights();
            t.equalizeHeights();
        });
    },

    removeEqualHeights: function () {
        if (this.blocks.length > 0) {
            this.blocks.forEach(function (block) {
                block.forEach(function (item) {
                    item.height('auto');
                });
            });
        }
    }
};


/**
 * Created by piemontese on 19.02.15.
 */

( function( $ ) {

	nfcPart2Article = {
		options: {
			sparePartsTab		 		: "sparePartsTab",
			sparePartsContainer	 		: "sparepartsContainer",
			sparePartsDetailTab			: "sparepartsDetailTab",
			sparePartsDetailContainer	: "sparepartsDetailsContainer",
			sparePartsCategories 		: "partCategoryBox",
			sparePartsCategoryHead 		: "partContainerHeading",
			sparePartsCategoryLink		: "partCategory",
			sparePartsCategoryLi		: "partCategoryLi",
			openAccessoryTab			: false,
			sparePartsCategoryLevel		: "data-level",
			sparePartsCategoryEvent		: "data-event",
			sparePartsCategoryId		: "data-actid",
			sparePartsCategoryRootId	: "data-actrootid",
			sparePartsCategoryFRootId	: "data-actfakerootid",
			sparePartsTabClose			: "tabCloser",
			sparePartsTabCloseLink		: "tabCloserLink"
		},

		_create: function() {

			var self     	= this;
			var options 	= self.options;
			var el      	= self.element;

			el.on( 'click', function( event ) {
				event.stopPropagation();
				event.preventDefault();
				var categoryContainer =  $( 'div#' + options.sparePartsContainer );
				categoryContainer.css('min-height','6.25em');
				if( isEmpty( $( categoryContainer) ) && el.attr( 'data-url' ) )
				{
					el.attr( 'href', el.attr( 'data-url' ) );
					self.requestAndFillSparePartsCategories( el );
					el.attr( 'href','#'+options.sparePartsContainer );
				}
			});

			return;
		},

		bindCategoryEventLink: function()
		{
			var self 		= this;
			var options 	= self.options;

			$( '.' + options.sparePartsCategoryLink ).each( function(i){

				$(this).on('click',function(ev){

					ev.preventDefault();

					$( '.' + options.sparePartsCategoryLink ).css({'font-weight' : 'normal'});

					$(this).css({
						'font-weight' : 'bold',
						'text-decoration' : 'underline'
					});

					self.highlightCategoryPath( $( this ) );
					var oSubCats = self.getCategorySubCats( $( this ) );
					var event = self.getPartCategoryEvent( $( this ) );
					if( oSubCats &&  event === 'category' )
					{
						oSubCats.toggle();
					}
					else
					{
						if( event === 'accessory' )
						{
							options.openAccessoryTab = true;
							$( this ).attr( 'href', $( this ).attr( 'data-url' ) );
							self.requestAndFillSparePartsDetailContainer( $( 'div#' + options.sparePartsDetailContainer ), self.getPartCategoryId( $( this ) ), self.getPartCategoryRootId( $( this ) ) );
							$( this ).attr( 'href','#'+options.sparePartsDetailContainer );
						}
					}

					return false;
				});
			});

		},

		/**
		 *
		 * @param oElement
		 */
		highlightCategoryPath: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			var partCatLev	= self.getPartCategoryLevel( oElement );
			var that 		= $(oElement);
			while( partCatLev > 1 )
			{
				var parentCat 	= that.parent('li').parent('ul').parent('li').find('a.partCategory').first();

				if( parentCat != undefined ){
					parentCat.css({
						'font-weight' : 'bold',
						'text-decoration' : 'underline'
					});
				}

				partCatLev = partCatLev - 1;
				that = parentCat;
			}

			return false;
		},

		/**
		 *
		 * @param oElement
		 */
		requestAndFillSparePartsDetailContainer: function( oElement, partCatId, partRootCatId )
		{
			var self 		= this;
			var options 	= self.options;

            $( 'a#' + self.options.sparePartsDetailTab).parent('.tabElement').show();
			$( 'a#' + self.options.sparePartsDetailTab).show().trigger( 'click' );

			var aOptions = {}, target = $( 'div#' + self.options.sparePartsDetailContainer );
			target.css('min-height','6.25em');

			$( target ).html( '' );

			aOptions[ 'ajaxPartDetail' ] 	= '1';
			aOptions[ 'partCatId' ] 		= partCatId;
			aOptions[ 'partRootCatId' ] 	= partRootCatId;
			oxAjax.ajax(
				oElement, {
					'targetEl'  : $( target ),
					'iconPosEl' : $( target ),
					'additionalData' : aOptions,
					'onSuccess' : function(r) {
						$( target ).html( r );
						self.bindCloseEventSparePartsDetailContainer();
						var table = $(".sortable").stupidtable();
						if (table.length > 0) {
							table.on("aftertablesort", function (event, data) {
								var th = $(this).find("th");
								th.find(".triangle-wrapper").remove();
								var dir = $.fn.stupidtable.dir;
								th.eq(data.column).append('<span class="triangle-wrapper ' + data.direction + '"><span></span></span></span>');
							});
							var th_to_sort = table.find("th").first();
							if (th_to_sort.length > 0) {
								th_to_sort.stupidsort('asc');
								console.log('yay');
							}
						}
					}
				}
			);
			return false;
		},

		bindCloseEventSparePartsDetailContainer: function()
		{
			var self 		= this;
			var options 	= self.options;

			$( 'div.' + self.options.sparePartsTabClose ).each( function(i){
				$(this).on('click',function(ev){
					ev.preventDefault();
                    $( 'a#' + self.options.sparePartsDetailTab).parent('.tabElement').hide();
					$( 'a#' + self.options.sparePartsDetailTab).hide();
					$( 'div#' + self.options.sparePartsDetailContainer).html('').hide();
					$( 'a#' + self.options.sparePartsTab).trigger( 'click' );
					return false;
				});
			});

			$( 'a.' + self.options.sparePartsTabCloseLink ).each( function(i){
				$(this).on('click',function(ev){
					ev.preventDefault();
					$( 'a#' + self.options.sparePartsDetailTab).parent('.tabElement').hide();
					$( 'a#' + self.options.sparePartsDetailTab).hide();
					$( 'div#' + self.options.sparePartsDetailContainer).html('').hide();
					$( 'a#' + self.options.sparePartsTab).trigger( 'click' );
					return false;
				});
			});
		},

		/**
		 *
		 * @param oElement
		 * @returns bool|object
		 */
		getCategorySubCats: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;

			var nexLevel = parseInt( self.getPartCategoryLevel( oElement ) ) + 1 ;
			var oSubLevel = oElement.parents( 'li.' + self.options.sparePartsCategoryLi ).first().find( 'ul.level_' + nexLevel );
			if( oSubLevel.size() > 0 ){
				return oSubLevel;
			}

			return false;
		},

		requestAndFillSparePartsCategories: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;

			var aOptions = {}, target = $( 'div#' + options.sparePartsContainer );
			aOptions[ 'ajaxPart' ] = '1';
			oxAjax.ajax(
				oElement, {
					'targetEl'  : $( target ),
					'iconPosEl' : $( target ),
					'additionalData' : aOptions,
					'onSuccess' : function(r) {
						$( target ).html( r );
						self.bindCategoryEventLink();
					}
				}
			);
			return false;
		},

		/**
		 *
		 * @param oElement
		 * @returns string
		 */
		getPartCategoryLi: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			return oElement.parent( 'li.' + self.options.sparePartsCategoryLi );
		},

		/**
		 *
		 * @param oElement
		 * @returns string
		 */
		getPartCategoryEvent: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			return oElement.attr( self.options.sparePartsCategoryEvent );
		},

		/**
		 *
		 * @param oElement
		 * @returns string
		 */
		getPartCategoryLevel: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			return oElement.attr( self.options.sparePartsCategoryLevel );
		},

		/**
		 *
		 * @param oElement
		 * @returns string
		 */
		getPartCategoryId: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			return oElement.attr( self.options.sparePartsCategoryId );
		},

		/**
		 *
		 * @param oElement
		 * @returns string
		 */
		getPartCategoryRootId: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			return oElement.attr( self.options.sparePartsCategoryRootId );
		},

		/**
		 *
		 * @param oElement
		 * @returns string
		 */
		getPartCategoryFRootId: function( oElement )
		{
			var self 		= this;
			var options 	= self.options;
			return oElement.attr( self.options.sparePartsCategoryFRootId );
		}

	};

	/**
	 * Runs defined scripts inside the method, before ajax is called
	 */
	function preAjaxCaller() {
//		$('#zoomModal').remove();
	}


	function isEmpty( el ){
		return !$.trim(el.html())
	}

	/**
	 * Part2Article widget
	 */
	$.widget("ui.nfcPart2Article", nfcPart2Article );


} )( jQuery );

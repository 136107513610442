/**
 * Created by mtroeger on 02.03.2017.
 */



/**
 * Scales the images with the class ".js-scale-image" depending on the font size set in the body tag.
 *
 * Each Image with the class is stored in the scalingImages array additional with the origin image width.
 * The height of the images will set to auto to keep the ratio.
 * When the windows was resized a scaling factor from origin to current size will calculated and the
 * width attribute will be overwritten.
 */

/**
 * Contains all images with the class ".js-scale-image"
 * @type {Array}
 */
var scalingImages = [];

/**
 * Font size on full screen
 * @type {number}
 */
var initialFontSize = 16;

var scaleImagesInit = function() {
    $('.js-scale-image').each(function () {
        var scalingImg = {
            'img': this,
            'originWidth': this.attributes.width.value
        };
        scalingImages.push(scalingImg);
        this.attributes.height.value = "auto";
    });

    if (scalingImages.length > 0) {
        scaleImages();
        window.addEventListener("resize", scaleImages);
    }
}

var scaleImages = function () {
    var currentFontSize = parseFloat($('body').css('font-size'));
    var scalingFactor = initialFontSize / currentFontSize;
    scalingImages.forEach(function (t) {
        t.img.attributes.width.value = t.originWidth / scalingFactor;
    });
};


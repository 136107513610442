/**
 * Created by piemontese on 19.02.15.
 * Erweitert von Jan Sträter, neusta sd, 04.04.2016
 */

(function ($) {

    nfcMyAccount = {
        options: {
            dataClass: "data-class",
            compareAdd: "add",
            compareRemove: "remove",
            compareAid: "data-aid",
            targetListIcon: "div.listDetails",
            targetDetailIcon: "div.information",
            compareLink: "compare",
            noticeLink: "linkToNoticeList",
            tinyNoticeLink: "tinyLinkToNoticeList",
            closePopupTimeout: 4500, /* 4 Sekunden plus die 500 Millisekunden der Animation */
            popupFadeTime: 500
        },
        closePopupTimerId: null,

        setupTimerToClosePopup: function() {
            var that = this;
            this.closePopupTimerId = setTimeout(
                function(){
                    that.removeArticleToListPopup();
                },
                this.options.closePopupTimeout
            );
        },

        removeTimerToClosePopup: function() {
            if (this.closePopupTimerId != null) {
                clearTimeout(this.closePopupTimerId);
                this.closePopupTimerId = null;
            }
        },

        _create: function () {
            var self = this;
            var options = self.options;
            var el = self.element;

            el.click(function (ev) {
                var dataClass = el.attr(options.dataClass);
                if (dataClass == 'alist' || dataClass == 'details') {
                    ev.preventDefault();
                }
                else {
                    var href = el.attr('href');
                    if (href) {
                        window.location = href;
                    }

                    return false;
                }

                var targetPosIconEl;
                var list;

                if (el.hasClass(options.compareLink)) {
                    list = 'Compare';
                }
                else if (el.hasClass(options.noticeLink)) {
                    list = 'Notice';
                }
                else if (el.hasClass(options.tinyNoticeLink)) {
                    list = 'Notice';
                }

                if (el.closest(options.targetDetailIcon).length > 0) {
                    targetPosIconEl = el.closest(options.targetDetailIcon);
                } else {
                    var parent = el.parent().parent().parent().parent().parent();
                    if (parent.find(options.targetListIcon).length > 0) {
                        targetPosIconEl = parent.find(options.targetListIcon);
                    }
                }

                if (targetPosIconEl && list) {
                    self.toList(el, targetPosIconEl, list);
                }
                else {
                    var href = el.attr('href');
                    if (href) {
                        window.location = href;
                    }

                    return false;
                }
            });

            $(window).resize(function () {
                self.alignArticleToListPopup();
            });

            $('body').on('click', '.btn_CloseArticleToListPopup, .articleToListPopupBackdrop', function (e) {
                self.removeArticleToListPopup();
            });

            $('body').on('mouseenter', '.articleToListPopup', function(e){
                self.removeTimerToClosePopup();
            })
        },

        alignArticleToListPopup: function () {
            var popup = $('#articleToListPopupWrapper .articleToListPopup').first();
            if (popup.length > 0) {
                var isMobile = $('body').hasClass('mobileDevice');
                var popupWidth = $(popup).outerWidth(true);
                var popupHeight = $(popup).outerHeight(true);
                var left = 0;
                var top = 0;
                if (isMobile) {
                    $(popup).find('.articleToListPopupPointer').remove();
                    var windowWidth = $(window).width();
                    var windowHeight = $(window).height();
                    left = (windowWidth - popupWidth) / 2;
                    top = (windowHeight - popupHeight) / 2;
                } else {
                    var desktopMenuItem = $('.headerTopMenu .listCountsTriggerAjax').first();
                    if (desktopMenuItem.length > 0) {
                        var positionOfMenuItem = $(desktopMenuItem).offset();
                        var widthOfMenuItem = $(desktopMenuItem).outerWidth(true);
                        var heightOfMenuItem = $(desktopMenuItem).outerHeight(true);
                        var scrollTop = $(window).scrollTop();
                        left = Math.round(positionOfMenuItem.left + (widthOfMenuItem / 2) - popupWidth + 64);
                        top = Math.round(positionOfMenuItem.top - scrollTop + heightOfMenuItem + 32);
                    }
                }
                $(popup).css('left', left.toString() + 'px');
                $(popup).css('top', top.toString() + 'px');
            }
        },

        createArticleToListPopup: function (sPopupHtml) {
            $('<div id="articleToListPopupWrapper">' + sPopupHtml + '</div>').appendTo($('body'));
            this.setupTimerToClosePopup();
            $('#articleToListPopupWrapper')
                .hide()
                .fadeIn(this.options.popupFadeTime);
            ;
        },

        removeArticleToListPopup: function () {
            var that = this;
            $('#articleToListPopupWrapper').fadeOut(this.options.popupFadeTime, function(){
                $('#articleToListPopupWrapper').remove();
                that.removeTimerToClosePopup();
            });
        },

        /**
         *
         * @param oElement
         */
        toList: function (oElement, oTargetPosEl, list) {
            var self = this;
            var options = self.options;

            var aOptions = {}, target = oTargetPosEl;

            aOptions['ajax' + list] = '1';
            oxAjax.ajax(
                oElement, {
                    'targetEl': $(target),
                    'iconPosEl': $(target),
                    'additionalData': aOptions,
                    'onSuccess': function (data) {
                        var jsonData = $.parseJSON(data);
                        if (list == 'Compare') {
                            var id = oElement.attr(options.compareAid);
                            if (jsonData.iEvent > 0) {
                                $("a.compare.add[data-aid='" + id + "']").css('display', 'none');
                                $("a.compare.remove[data-aid='" + id + "']").css('display', 'block');
                            }
                            else {
                                $("a.compare.add[data-aid='" + id + "']").css('display', 'block');
                                $("a.compare.remove[data-aid='" + id + "']").css('display', 'none');
                            }
                        }

                        var spanCount = $('span.counter.' + list + 'Count');
                        if (jsonData.iCount > 0 && spanCount.length > 0) {
                            spanCount.html('').append('<span class="counterInner">' + jsonData.iCount + '</span>');
                        }
                        else if (jsonData.iCount == 0 && spanCount.length > 0) {
                            spanCount.html('');
                        }

                        var spanTotCount = $('span.counter.TotalCount');
                        if (jsonData.iTotCount > 0 && spanTotCount.length > 0) {
                            spanTotCount.html('').append('<span class="counterInner">' + jsonData.iTotCount + '</span>');
                        }
                        else if (jsonData.iTotCount == 0 && spanTotCount.length > 0) {
                            spanTotCount.html('');
                        }

                        if (jsonData.sPopupAjax) {
                            self.removeArticleToListPopup();
                            self.createArticleToListPopup(jsonData.sPopupAjax);
                            self.alignArticleToListPopup();
                        }

                    }
                }
            );
            return false;
        }
    };

    $.widget("ui.nfcMyAccount", nfcMyAccount);


})(jQuery);

var ceCounterBar = {

    '_barElements': [],

    '_settings': {
        inViewPercent: 0.2
    },

    init: function () {
        this._findAllBars();
        this._setEventListener();
        this._startBarsWhenInViewport();
    },

    '_findAllBars': function () {
        var obj = this;
        $('.ce-counterBar').each(function (i, barElement) {
            var jBarElement = $(barElement);
            obj._barElements.push({
                jElem: jBarElement,
                offset: barElement.parentNode.offsetTop
            });
            obj._setDurationToElement(jBarElement);
        });
    },

    '_setEventListener': function () {
        var obj = this;
        $(window).scroll(function () {
            obj._startBarsWhenInViewport();
        });
    },

    '_startBarsWhenInViewport': function () {
        var obj = this;
        this._barElements.forEach(function (barElement) {
            if (obj._isViewPointReached(barElement)) {
                obj._startAnimation(barElement);
            }
        });
    },

    '_startAnimation': function (barElement) {
        barElement.jElem.addClass('go');
        barElement.jElem.find('.bar').first().css('width', this._setWidth(barElement));
    },

    '_setDurationToElement': function (jBarElement) {
        jBarElement.find('.bar').first().css('transition-duration', jBarElement.data('duration') + 'ms');
    },

    '_isViewPointReached': function (barElement) {
        var
            currentOffset = window.pageYOffset + window.innerHeight,
            inSightAddition = window.innerHeight * this._settings.inViewPercent,
            elementOffset = barElement.offset + inSightAddition;

        return currentOffset > elementOffset;
    },

    '_setWidth': function (barElement) {
        return barElement.jElem.data('width') + '%';
    },

};

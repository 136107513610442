/**
 * Created by mtroeger on 13.03.2017.
 */

var filmsModal = {

    ratio: {
        x: 16,
        y: 9
    },

    init: function () {
        var items = $ ('.filmModal');
        filmsModal.setClickEvents (items);
    },

    setClickEvents: function (items) {
        $ (items).each (function () {
            var isFilmSlider = this.closest ('.filmSlider') !== null;
            var isCenteredSlide = this.parentNode.classList.contains ('center');

            if (!isFilmSlider || (isFilmSlider && isCenteredSlide)) {
                $ (this).find ('.image > a').first ().click (function (event) {
                    var filmId = this.getAttribute ('data-providerid');
                    var providerId = this.getAttribute ('data-provider');

                    filmsModal.openLightboxAndPlayFilm (providerId, filmId);
                    event.preventDefault ();
                });
            }
        });
        filmsModal.openLightboxWhenGetParamsGiven ();
    },

    openLightboxWhenGetParamsGiven: function () {
        var providerId = filmsModal.getUrlParameter ('provId');
        var filmId = filmsModal.getUrlParameter ('filmId');
        var comingSoon = filmsModal.getUrlParameter ('comingSoon');

        if (providerId && filmId) {
            filmsModal.openLightboxAndPlayFilm (providerId, filmId);
        } else if (comingSoon == 1) {
            filmsModal.openLightboxAndShowImage ('comingSoon');
        }
    },

    openLightboxAndPlayFilm: function (providerId, filmId) {
        var provider = filmProvider[providerId];
        var uri = provider.url + provider.prefix + filmId + provider.suffix;
        var size = filmsModal.calculateIFrameSize ();

        // NXPMET-252 and -259: We need to set the src later to make sure the consent manager can check if
        // YouTube is allowed or not.
        $.featherlight ('<iframe style="width:' + size.width + 'px;height:' + size.height + 'px"></iframe>', {
            afterOpen: function () {
                this.$content[0].src = uri;
            },
            onResize: function () {
                var size = filmsModal.calculateIFrameSize ();
                $ ('.featherlight iframe').css ({width: size.width, height: size.height});
            }
        });
    },

    openLightboxAndShowImage: function () {
        var lightboxSize = filmsModal.calculateIFrameSize ();
        var content = '<div class="filmComingSoon" style="background-image: url('
            + filmComingSoon + '); width:'
            + lightboxSize.width + 'px; height:'
            + lightboxSize.height + 'px"></div>';

        $.featherlight (content);
    },

    calculateIFrameSize: function () {
        //get max sizes of modal
        var maxWidth = Math.floor (window.innerWidth * .9);
        if (maxWidth > 1280) maxWidth = 1280;
        var maxHeight = Math.floor (window.innerHeight * .9);

        //calculate height by a ratio of 4:3 with maxWidth
        var width = maxWidth;
        var height = Math.floor (maxWidth / filmsModal.ratio.x * filmsModal.ratio.y);

        //if height overlaps maxHeight, set height to maxHeight and recalculate width
        if (height > maxHeight) {
            height = maxHeight;
            width = Math.floor (height / filmsModal.ratio.y * filmsModal.ratio.x);
        }

        //if width overlaps maxWidth, set width to maxWidth and recalculate height
        if (width > maxWidth) {
            width = maxWidth;
            height = Math.floor (width / filmsModal.ratio.x * filmsModal.ratio.y);
        }

        return {
            'width': width,
            'height': height
        };
    },

    getUrlParameter: function getUrlParameter (sParam) {
        var sPageURL = decodeURIComponent (window.location.search.substring (1)),
            sURLVariables = sPageURL.split ('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split ('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }
};

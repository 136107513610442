
var ceCounterCircle = {

    '_options': {
        scaleColor: false,
        trackColor: 'rgba(0,0,0,.1)',
        barColor: '#224b44',
        lineWidth: 16,
        lineCap: 'butt',
        size: 200,
        animate: 2000,
        onStart: function () {
            this.elem = $(this.el);
            this.numValue = this.elem.data('num-value');
        },
        onStep: function (from, to, current) {
            var currValue = this.numValue / to * current;
            this.elem.find('span').text(Math.round(currValue));
        },
        onStop: function () {
            this.elem.find('span').text(this.numValue);
        }
    },

    '_settings':{
        inViewPercent: 0.1
    },

    '_charts': [],

    init: function () {
        this._findAllCharts();
        this._setEventListener();
        this._startChartWhenInViewport();
    },

    '_findAllCharts': function () {
        var obj = this;
        $('.ce-counterCircle .chart').each(function (i, chart) {
            var d = $(chart).data('duration');
            obj._charts.push({
                el: chart,
                offset: chart.offsetTop,
                started: false,
                duration: d
            });
        });
    },

    '_setEventListener': function () {
        var obj = this;
        $(window).scroll(function () {
            obj._startChartWhenInViewport();
        });
    },

    '_startChartWhenInViewport': function () {
        var currentOffset = window.pageYOffset + window.innerHeight;
        var obj = this;
        this._charts.forEach(function(chart){
            var elementOffset = chart.el.offsetTop + (window.innerHeight * obj._settings.inViewPercent);
            if(currentOffset > elementOffset){
                if(chart.duration > 0){
                    obj._options.animate = chart.duration;
                }
                $(chart.el).easyPieChart(obj._options);
            }
        });
    }
};

( function( $ ) {

    /*resonsive and mobile*/
    $.setBodyFontSize = function(){
        var p = ($('#page').width() / 1280) * 100;
        // changed font-size from body to html tag, to enable rem
        $('html').css({'font-size' : p + '%'});
    };

    $.fn.initCompareList = function(){
        var list = $(this);
        $(list).find('tr.groupTitle').each(function(){
            if( $(this).attr('id') ){
                $(this).click(function() {
                    $(list).find('tr.' + $(this).attr('id')).toggle();
                    $(this).find('.icon').toggleClass('closed');
                    return true;
                });
            }
        });
    }

    $.fn.scrollTo = function( target, options, callback ){
        if(typeof options == 'function' && arguments.length == 2){ callback = options; options = target; }
        var settings = $.extend({
            scrollTarget  : target,
            offsetTop     : 50,
            duration      : 500,
            easing        : 'swing'
        }, options);
        return this.each(function(){
            var scrollPane = $(this);
            var scrollTarget = (typeof settings.scrollTarget == "number") ? settings.scrollTarget : $(settings.scrollTarget);
            var scrollY = (typeof scrollTarget == "number") ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop);
            scrollPane.animate({scrollTop : scrollY }, parseInt(settings.duration), settings.easing, function(){
                if (typeof callback == 'function') { callback.call(this); }
            });
        });
    }

    $.fn.centerHeading = function() {
        var containerHeight = $(this).find('.boxContainer').find('.boxContent').height();
        var headingHeight = $(this).find('.boxContainer').find('.boxHeadline').height();
        var top = ((containerHeight/2) - (headingHeight / 2)) / 16;
        $(this).find('.boxContainer').find('.boxHeadline').css('margin-top', top + 'em' );
    }

    $.fn.initInfoLink = function() {
        var mark = $(this).attr('href');

        $(this).click(function(ev) {
            ev.preventDefault;
            $('#itemTabs').find('a[href=' + mark + ']').trigger('click');
            $('html').scrollTo(mark);
        });
    }

    /*
     * Category Slider DCE jQuery Slide Function
     * */
    $.initCategorySlider = function(){

        var slides = $(".slide");
        var numberOfSlides = slides.length; //z�hlt die Anzahl der slide-Container, also Seiten/Bilder
        var slideWidth = $(".slide").width(); //speichert die Breite eines slide-Containers in slideWidth
        var maxSlideWidth = (parseInt(slideWidth) * numberOfSlides) + parseInt(slideWidth); //legt die maximale Slidebreite fest

        var mobileSlideWidth = 100 / numberOfSlides;
        var mobileSlideWidthPercent = mobileSlideWidth+"%";

        if($( window ).width() < 641){
            $(".inner").css({"width":$( window ).width() * numberOfSlides}); //gibt .inner die maximale Breite, die er sliden darf
            $(".inner > div").css({"width":mobileSlideWidthPercent}); //gibt .inner die maximale Breite, die er sliden darf
            $(".slideshow .control").css({"top":"80%"}); //gibt .inner die maximale Breite, die er sliden darf
            $(".slideshow .control").css({"position":"absolute"}); //gibt .inner die maximale Breite, die er sliden darf
            $("body.mobileDevice .boxShortText").css({"max-height":"2.25em"}); //gibt .inner die maximale Breite, die er sliden darf

            $(window).resize(function() {
                $(".inner").css({"width":$( window ).width() * numberOfSlides}); //gibt .inner die maximale Breite, die er sliden darf
                $(".inner > div").css({"width":mobileSlideWidthPercent}); //gibt .inner die maximale Breite, die er sliden darf
                $(".slideshow .control").css({"top":"80%"}); //gibt .inner die maximale Breite, die er sliden darf
                $(".slideshow .control").css({"position":"absolute"}); //gibt .inner die maximale Breite, die er sliden darf
                $("body.mobileDevice .boxShortText").css({"max-height":"2.25em"}); //gibt .inner die maximale Breite, die er sliden darf
            });
        }else{
            $(".inner").css({"width":maxSlideWidth}); //gibt .inner die maximale Breite, die er sliden darf
        }

        jQuery(".slide_thumb").on("click",function(){  //klick auf thumbnail

            $(".control .slide_thumb").css({"background":"grey"});
            $(this).css({"background":"white"});

            var $thumbNo = $(this).attr('id').slice(6);

            if($thumbNo > 0){
                $(".inner").stop().animate({"margin-left":"-"+ 100*$thumbNo+"%"},1000); //animiere slider nach rechts
            }else{
                $(".inner").stop().animate({"margin-left":"0"},1000); //animiere slider nach rechts
            }
        });
    };
    /* END Category Slider */

    /*navigation delay*/
    $.initMainMenu = function() {
        if( $('li.mainLevel1').length > 0){
            $("li.mainLevel1").each(function() {
                $(this).find('ul.mainNavigationLevel2').css( 'display', 'none' );
                var delay = 300, setFlyTimer;
                $(this).mouseenter(function() {
                    var hoveredElement = $(this);
                    setFlyTimer = setTimeout(function() {
                        (location.href.indexOf('nofade')>-1) ?
                            $(hoveredElement).find('ul.mainNavigationLevel2').css('display', 'block') :
                            $(hoveredElement).find('ul.mainNavigationLevel2').fadeIn(300);
                        $('#footer').css({'z-index':'0'});
                    }, delay);

                });
                $(this).mouseleave(function(){
                    clearTimeout(setFlyTimer);
                    if( $('.ui-autocomplete').is(":visible") ){
                        return false;
                    }
                    (location.href.indexOf('nofade')>-1) ?
                        $(this).find('ul.mainNavigationLevel2').css('display', 'none') :
                        $(this).find('ul.mainNavigationLevel2').fadeOut(300);
                    $('#footer').css({'z-index':'10010'});
                });
            });
        }
    };
    /*navigation delay*/

    /* start video */
    $.fn.startVideo = function(delay) {
        var videoIframe = $(this).closest(".videoBox").find('.boxVideoPlayer iframe');
        var videoSrc = $(this).closest(".videoBox").find('.boxVideoPlayer iframe').attr("src");
        var _this =  $(this);
        $(this).closest(".videoBox").find('.boxVideoPlayer iframe').attr("src", videoSrc+"&autoplay=1");
        setTimeout(
            function()
            {
                _this.closest(".videoBox").find('.boxContentContainer').fadeOut("2000");
                _this.closest(".videoBox").find('img').fadeOut("3000");
                _this.closest(".videoBox").find('.boxVideoPlayer').fadeIn("4000");
            }, delay);
    };
    /* start video */


    $.initDesktopToolTip = function() {

        $('.symbolToolTip.tooltip').bind( "mouseleave", function( event ) {
            event.stopImmediatePropagation();
            id = $(this).attr('id');

            var fixed = setTimeout('$("#"+id+".symbolToolTip.tooltip").tooltip("close")', 300);

            $(".ui-tooltip").hover(
                function(){clearTimeout (fixed);},
                function(){$(".symbolToolTip.tooltip").tooltip("close");}
            );
        }).bind( "mouseenter", function( ) {

            $(".symbolToolTip.tooltip").not($(this)).tooltip("close");

        }).tooltip({
            focusPreventClose: true,
            content: function() {
                var element = $( this );
                var output = element.attr('title');

                if ( element.is( "[data-attr-tip]" ) ) {
                    var text = element.attr('title');
                    var src = element.attr('src');
                    output = "<img " + "src='"+ src +"' style='width:32px;float:left' >" +
                        "<span style='margin-left:42px;display:block;vertical-align: top'>"+text+"</span>";
                }
                if ( element.is( "[data-link]" ) ) {
                    var link = element.data('link');

                    output += "<a class='' href='"+link+"' target='_blank' >"
                        +"<span class='icon'></span>"
                        +"<span class=iconText>"+link+"</span>"
                        +"</a>";
                }

                return output;

            }
        }).each(function(){
            //Dynamische Position hinzufügen
            var position;
            var element = $(this);
            position = {
                my: $(this).attr('data-my'),
                at: $(this).attr('data-at'),
                collision: "none",
                using: function( position, feedback ) {
                    $( this ).css( position );
                    $( "<div>" )
                        .addClass( element.attr('data-arrow') )
                        .addClass( feedback.vertical )
                        .addClass( feedback.horizontal )
                        .appendTo( this );
                }
            };
            $(this).tooltip('option', 'position', position);
        });

        $('body').on('mouseleave', '.ui-tooltip', function(){
            $('.symbolToolTip.tooltip').tooltip('close');
        });



        $('.storeLocatorTooltip.tooltip').tooltip({
            position: {
                my: "left center",
                at: "right+10 center",
                collision: "none",
                using: function( position, feedback ) {
                    $( this ).css( position );
                    $( "<div>" )
                        .addClass( "arrow_box_left" )
                        .addClass( feedback.vertical )
                        .addClass( feedback.horizontal )
                        .appendTo( this );
                }
            }
        });
    };

    $.initMobileToolTip = function() {
        $('.tooltip').tooltip();
    };

})(jQuery);
